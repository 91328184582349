import React from "react"

import I18n from '../components/i18n'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container grid-lg" style={{ margin: '40px auto' }}>
      <h1 className="text-center">404 NOT FOUND</h1>
      <p className="text-center">The requested page was not found</p>
    </div>
  </Layout>
)

export default NotFoundPage
